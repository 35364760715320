export const appConfigs = {
    zero: 0,
    page_init: 1,
    per_page : 15,
    column_default : 'id',
    orientationAsc : 'asc',
    orientationDesc : 'desc',
    actionAdd: 'create',
    actionUpdate: 'update',
    roleClient: 'user',
    roleProvider: 'provider',
    roleAdmin: 'admin',
    defaultPage: 'dashboard',
    evaluatorLink: 'link',
    evaluatorSubItem: 'sub',
    classOpenNav: 'nav-open',
    classSidebarMini: 'sidebar-mini',
    bgColor: 'bg-white',
    navBarTransparent: 'navbar-transparent',
    titlePageClient: 'listClients',
    titlePageProvider: 'listProviders',
    titlePageAdmin: 'listAdmins',
    titleModalClient: 'editCreateClients',
    titleModalProvider: 'editCreateProviders',
    titleModalAdmin: 'editCreateAdmins',
    typeFather: 'father',
    typeLink: 'link',
    minHour: '00:00',
    image: 'image',
    video: 'video',
    maxPhones: 4,
    maxImages: 10,
    maxVideos: 1,
    maxItemsMedia: 11,
    maxItemsCoverBackground: 2,
    imagePlaceHolder: '/assets/img/placeholder.png',
    empty: '',
    newIndex: -1,
    maxSizeFileImage: 3072,
    maxSizeFileVideo: 4096,
    maxSizeFileImageMessage: 'maxSizeFileImageMessage',
    maxSizeFileVideoMessage: 'maxSizeFileVideoMessage',
    toolbarEditorDefault: [
        ['bold', 'italic'],
        ['underline'],
        [{ heading: ['h1', 'h2', 'h3'] }],
        ['link'],
        ['text_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ],
    mediumSize: 'md',
    largeSize: 'xl',
    idParamsGeneric: '{id}',
    maxStars: 5,
    starFull: 'fas fa-star',
    starEmpty: 'fas fa-star text-gray',
    userCodeBlocked: 423,
    dateFormatInput: 'yyyy-MM-ddTHH:mm',
    dateFormatShortInput: 'yyyy-MM-dd',
    coverPosition: 0,
    backgroundPosition: 1,
    indicatorSize: 1024,
    countryPhone: 'EC',
    phoneFormat: 'tel://',
    whatsappFormatUrl: 'https://wa.me/',
    whatsappFormatText: '/?text=Hola estoy interesado',
    instagramFormatUrl: 'https://instagram.com/',
    facebookFormatUrl: 'https://facebook.com/',
    teamQWebsiteUrl: 'https://teamq.biz',
    positionIndex: 0,
    boolean: {
        true: 1,
        false: 0
    },
    perPageThousand: 1000,
    map: {
        zoomMap: 5,
        defaultUbication: {
            lat: -0.208946,
            lng: -78.507751
        }
    },
    ten: 10,
    fiveHundred: 500
};

